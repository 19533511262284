import { Modal } from 'bootstrap'


//window.addEventListener("load", function() {
    const modalFeedback = new Modal(document.getElementById('modal-feedback'))

    document.querySelector('#form-feedback').addEventListener("submit", function(e) {
        e.preventDefault(); // before the code
        const button = document.querySelector('#form-feedback button');
        button.disabled = true;


        axios.post('/send-email-feedback', {
            email: document.querySelector('#form-feedback input[name=email]').value,
            message: document.querySelector('#form-feedback textarea[name=message]').value,
        })
            .then(function (response) {
                if (response.data.result) {
                    document.querySelector('#modal-feedback .modal-body').innerHTML = response.data.message
                } else if (response.data.message) {
                    document.querySelector('#modal-feedback .modal-body').innerHTML = response.data.message
                } else {
                    document.querySelector('#modal-feedback .modal-body').innerHTML = 'Произошла ошибка при отправке e-mail. Пожалуйста, свяжитесь с нами другим способом.'
                }

                modalFeedback.show();
                e.target.reset();
                button.disabled = false;
            })
            .catch(function (error) {
                document.querySelector('#modal-feedback .modal-body').innerHTML = 'Произошла ошибка при отправке e-mail. Пожалуйста, свяжитесь с нами другим способом.'

                modalFeedback.show();
                button.disabled = false;
            });
        console.log('hi');
    })
//});
